<template>
  <div>
    <div class="mapwrap">
      <div class="mapbox">
        <div id="amap" style="width:100%;height:100%"></div>
        <div class="btnmode" @click="showLayer">
          <span v-if="satelliteMode">标准</span>
          <span v-else>卫星</span>
        </div>
      </div>

      <div class="detail">
        <div style="margin-bottom:20px;font-size:16px">移动标点选择位置，点击确定保存</div>
        <p>经度：{{point ? point[0] : '-'}}</p>
        <p>纬度：{{point ? point[1] : '-'}}</p>
        <button size="mini" class="btnmap" @click="commit">确定</button>
      </div>
    </div>
  </div>
</template>
<script>
let map, marker, satellite;
export default {
  props: {
    width: { type: String, default: "100%" },
    height: { type: String, default: "800px" },
    lnglat: {
      type: Array,
      validator: value => {
        return value.length === 2;
      }
    }
  },
  data() {
    return {
      address: "",
      point: this.lnglat,
      satelliteMode: true
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    // 初始化
    init() {
      let that = this;
      let lnglat = this.lnglat;
      map = new window.AMap.Map("amap", {
        resizeEnable: true,
        center: lnglat,
        zoom: 13,
        layers: [
          new window.AMap.createDefaultLayer()
          // 卫星
          // new window.AMap.TileLayer.Satellite()
        ]
      });
      satellite = new window.AMap.TileLayer.Satellite({ zoom: 14 });
      map.addLayer(satellite);
      that.addMark();
    },

    // 添加标记
    addMark() {
      let lnglat = this.lnglat;
      marker = new window.AMap.Marker({
        map: map,
        position: lnglat,
        draggable: true, // 允许拖动
        cursor: "move",
        raiseOnDrag: true
      });
      marker.on("dragend", () => {
        let position = marker.getPosition();
        this.point = [position.lng, position.lat];
      });
    },
    commit() {
      this.$emit("location", this.point);
    },
    showLayer() {
      if (this.satelliteMode) {
        map.removeLayer(satellite);
        this.satelliteMode = false;
      } else {
        this.satelliteMode = true;
        map.addLayer(satellite);
      }
    }
  },

  destroyed() {
    map && map.destroy();
    console.log("地图已销毁");
  }
};
</script>
   
<style scoped>
.mapwrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.mapbox {
  box-sizing: border-box;
  width: 75%;
  height: 500px;
  box-sizing: border-box;
  padding-right: 20px;
  border-right: 1px solid #ddd;
  position: relative;
}

.btnmode {
  position: absolute;
  right: 40px;
  top: 20px;
  padding: 5px 10px;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  box-shadow: 0 0 5px #999;
}

.detail {
  background-color: #fff;
  padding: 0 20px;
  box-sizing: border-box;
  word-wrap: break-word;
}
.btnmap {
  width: 100%;
  margin: 30px 0 0 0;
  padding: 5px 0;
  color: #fff;
  cursor: pointer;
  background-color: #409eff;
  border: none;
  border-radius: 3px;
}
</style>